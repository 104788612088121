import * as React from "react"
import { graphql, Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Box, Flex, SimpleGrid, VStack, Text } from "@chakra-ui/react"
import { Container } from "../components/Container"
import { Heading } from "../components/Heading"
import { Layout } from "../layouts/default"

import { FormulationChallengeBlock } from "../components/Blocks/FormulationChallengeBlock"
import { useMenuItems } from "../hooks/get-menu-items"
import { Helmet } from "react-helmet"
import { DynamicLink } from "../components/DynamicLink"
import langString from "../locales/lang.json"
import { useLocale } from "../context/locale/LocaleProvider"

const Sitemap = ({ data, pageContext }) => {
  const { locale } = pageContext
  const { state } = useLocale()
  const stateLocale = state?.locale || locale

  const menuItems = useMenuItems()

  return (
    <Layout>
      <Helmet>
        <title>Micropore Technologies - Sitemap</title>
        <meta
          name="description"
          content="This page provides an overview of all the pages on this website"
        />
      </Helmet>

      <Box
        height="500px"
        position="relative"
        mb={{ base: -12, md: -24 }}
        bg="blue.100"
      >
        <StaticImage
          src="../images/science-header.jpg"
          alt=""
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            zIndex: 1,
          }}
        />
      </Box>

      <Container px={0}>
        <Flex
          flexDir={{ base: "column", lg: "row" }}
          justifyContent={{ lg: "space-between" }}
        >
          <Box
            bg="white"
            width={{ lg: "50%" }}
            pt={{ base: 4, md: 12 }}
            pl={{ base: 4, md: 12 }}
            pr={{ base: 4, md: 12 }}
            pb={0}
            pos="relative"
            zIndex={2}
          >
            <Heading fontSize="40px" mb={6}>
              Sitemap
            </Heading>
          </Box>
        </Flex>

        <Container bg="white" mb={20} mt={{ md: 6, lg: 8 }}>
          <SimpleGrid
            columns={{ base: 1, md: 2, lg: 4 }}
            columnGap={16}
            mb={8}
            rowGap={{ base: 12, lg: 24 }}
          >
            {menuItems.map(item => {
              return (
                <Box key={item._key}>
                  <Heading as="h3" fontSize={24}>
                    {item.title[locale] || item.title.en}
                  </Heading>

                  <VStack>
                    {item.panelLinks &&
                      item.panelLinks.map(link => {
                        return (
                          <Box flex={1} width="100%">
                            <DynamicLink
                              to={link.to}
                              style={{ textDecoration: "none " }}
                            >
                              <Text>{link.title[locale] || link.title.en}</Text>
                            </DynamicLink>

                            {link.grandaughterLinks && (
                              <VStack pl={6}>
                                {link.grandaughterLinks.map(_link => {
                                  return (
                                    <Box flex={1} width="100%">
                                      <DynamicLink
                                        to={_link.to}
                                        style={{ textDecoration: "none " }}
                                      >
                                        <Text>
                                          {_link.title[locale] ||
                                            _link.title.en}
                                        </Text>
                                      </DynamicLink>
                                    </Box>
                                  )
                                })}
                              </VStack>
                            )}
                          </Box>
                        )
                      })}
                  </VStack>
                </Box>
              )
            })}

            <Box>
              <Heading as="h2" fontSize={28}>
                {langString["terms-of-use"][stateLocale]}
              </Heading>
              <VStack>
                <Box flex={1} width="100%">
                  <DynamicLink
                    to="/terms-and-conditions"
                    style={{ textDecoration: "none " }}
                  >
                    <Text>
                      {langString["terms-and-conditions"][stateLocale]}
                    </Text>
                  </DynamicLink>
                </Box>
                <Box flex={1} width="100%">
                  <DynamicLink
                    to="/terms-and-conditions"
                    style={{ textDecoration: "none " }}
                  >
                    <Text>{langString["privacy-policy"][stateLocale]}</Text>
                  </DynamicLink>
                </Box>
              </VStack>
            </Box>

            <Box>
              <Heading as="h2" fontSize={28}>
                {langString["accessibility"][stateLocale]}
              </Heading>
              <VStack>
                <Box flex={1} width="100%">
                  <DynamicLink
                    to="/sitemap"
                    style={{ textDecoration: "none " }}
                  >
                    <Text>{langString["sitemap"][stateLocale]}</Text>
                  </DynamicLink>
                </Box>
              </VStack>
            </Box>
          </SimpleGrid>
        </Container>

        <Container
          bg="white"
          pt={4}
          borderTopWidth={2}
          borderTopColor="gray.300"
        >
          <FormulationChallengeBlock />
        </Container>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query SitemapQuery {
    allSanityArticle(limit: 12, sort: { fields: publishedAt, order: DESC }) {
      edges {
        node {
          id
          publishedAt(formatString: "Do MMMM YYYY")
          publishedAtFr: publishedAt(formatString: "Do MMMM YYYY", locale: "fr")
          title {
            en
            fr
            es
            it
            de
            nl
            ko
            ja
          }
          subTitle {
            en
            fr
            es
            it
            de
            nl
            ko
            ja
          }
          slug {
            current
          }
          featureImage {
            asset {
              id
            }
          }
        }
      }
    }

    allSanityDocCategory {
      edges {
        node {
          id
          title {
            en
            fr
            es
            it
            de
            nl
            ko
            ja
          }
          slug {
            current
          }
        }
      }
    }
  }
`

export default Sitemap
